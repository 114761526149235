import React, { Component, FormEvent } from 'react';
import { Alert, Col, Row, DropdownToggle, Button, Dropdown, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink,Modal,ModalBody,ModalHeader,ModalFooter,Form,FormGroup,Label,Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import AppContext from "../AppContext";
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { ImageUploadMultiple } from './ImageUpload';
import { ImageUploadDragAndDrop } from './ImageUploadDragAndDrop';
import { Tooltip,UncontrolledTooltip } from 'reactstrap';
import { PageData } from './Timeline';
import { faFileExport, faFileXmark, faFolderArrowUp, faFolderCog, faFolderGear, faFolderPlus, faFolderXmark, faMagnifyingGlass, faRemove } from '@fortawesome/pro-solid-svg-icons';
import { ImageUploadNoCrop } from './ImageUploadNoCrop';

interface ImageListProps {
    onImageClick(img:ImageData): void;
    confirmSelection(images:ImageData[]): void;
    closeCallback():void;
    modalMode:boolean;
    mediaAddMode:string;
    singleSelect:boolean;
}

export interface ImageList extends React.Component {
    state: ImageListState;
    props: ImageListProps;
    updateTimer: NodeJS.Timeout | undefined;
}

interface ImageListState {
    errorMessage: string;
    infoMessage: string;
    fetching: boolean;
    language: string;
    images: ImageData[];
    conversationID: string;
    singleImageOperation: boolean;
    maxPages: number;
    curPage: number;
    pageSize: number;
    pagesArray: PageData[];
    pagesToShow: number;
    sort: string;
    sortDropdownOpen: boolean;
    pageSizeDropdownOpen: boolean;
    extraPagesDropdownOpen: boolean;
    foldersDropdownOpen: boolean;
    curFolderName: string;
    curFolderGuid: string;
    folders: FolderData[];
    createFolderModalOpen: boolean;
    modifyFolderModalOpen: boolean;
    deleteFolderModalOpen: boolean;
    moveFilesModalOpen: boolean;
    deleteFilesModalOpen: boolean;
    selectedImages: string[];
    moveToFolderDropdownOpen: boolean;
    moveToFolderGuid: string;
    moveToFolderName: string;
    fileSelectionDropdownOpen: boolean;
    modalMode: boolean;
    mediaAddMode: string;
    singleSelect: boolean;
    isMobile: boolean;
    platform: string;
}

export interface FolderData {
    guid: string;
    folder: string;
}

export interface ImageData {
    imageID: string;
    producerID: string;
    imageURL: string;
    adultRating: Number;
    uploadTime: Date;
    reviewTime: Date;
    reviewStatus: number;
    publishTime: Date;
    publishTargetGroup: string;
    additionalPeople: string;
    additionalPeopleReviewStatus: number;
    isProfilePic: boolean;
}

export class ImageList extends React.Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: ImageListProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let isMobile:boolean = this.detectiOS() || this.detectAndroid();

        let myplatform:string = "fans"

        this.state = {
            errorMessage: "",
            infoMessage: "",
            fetching: false,
            language: contextLanguage,
            conversationID: "",
            images: [],
            singleImageOperation: false,
            maxPages: 12,
            pageSize: 36,
            curPage: 0,
            pagesArray: [],
            pagesToShow: props.modalMode ? 3 : 10,
            sort: "uploadtime",
            sortDropdownOpen: false,
            pageSizeDropdownOpen: false,
            extraPagesDropdownOpen: false,
            foldersDropdownOpen: false,
            curFolderName: "",
            curFolderGuid: "",
            folders: [],
            createFolderModalOpen: false,
            modifyFolderModalOpen: false,
            deleteFolderModalOpen: false,
            moveFilesModalOpen: false,
            deleteFilesModalOpen: false,
            selectedImages: [],
            moveToFolderDropdownOpen: false,
            moveToFolderGuid: "",
            moveToFolderName: "",
            fileSelectionDropdownOpen: false,
            modalMode: props.modalMode,
            mediaAddMode: props.mediaAddMode,
            singleSelect: props.singleSelect,
            isMobile: isMobile,
            platform: myplatform
        }
    }

    componentDidMount() {
        this.fetchData();
        this.fetchFolders();
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.mediaAddMode != this.state.mediaAddMode) {
            this.setState({mediaAddMode: this.props.mediaAddMode});
        }
    }

    componentWillUnmount() {
      if (this.updateTimer) {
        clearInterval(this.updateTimer);
    }
    }

    detectAndroid():boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid;
    }

    detectiOS():boolean {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    handleImageSelect(imageID:string) {
        let selectedImages = this.state.selectedImages;
        if(selectedImages.indexOf(imageID) === -1) {
            selectedImages.push(imageID);
        } else {
            selectedImages.splice(selectedImages.indexOf(imageID),1);
        }
        this.setState({selectedImages: selectedImages});
    }

    handlePageChange(newPage:number):void {
        this.setState({curPage: newPage},this.fetchData.bind(this));
    }

    handleSortChange(sort:string) {
        this.setState({sort: sort},this.fetchData.bind(this));
    }

    handlePageSizeChange(pageSize:number) {
        this.setState({pageSize: pageSize},this.fetchData.bind(this));
    }

    handleSortDropdownToggle() {
        this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen });
    }

    handlePageSizeDropdownToggle() {
        this.setState({ pageSizeDropdownOpen: !this.state.pageSizeDropdownOpen });
    }

    handleExtraPagesDropdownToggle() {
        this.setState({ extraPagesDropdownOpen: !this.state.extraPagesDropdownOpen });
    }

    handleFoldersDropdownToggle() {
        this.setState({ foldersDropdownOpen: !this.state.foldersDropdownOpen });
    }

    handleFolderChange(folder:FolderData|null) {
        if(folder === null) {
            this.setState({curFolderName: "", curFolderGuid: ""},this.fetchData.bind(this));
            return;
        } else {
            this.setState({curFolderName: folder.folder, curFolderGuid: folder.guid},this.fetchData.bind(this));
        }
    }

    handleCreateFolderModalToggle() {
        this.setState({ createFolderModalOpen: !this.state.createFolderModalOpen },this.focusCreateFolder.bind(this));
    }

    handleFileSelectionDropdownToggle() {
        this.setState({ fileSelectionDropdownOpen: !this.state.fileSelectionDropdownOpen });
    }

    handleSelectAll() {
        let selectedImages = this.state.selectedImages;
        let images = this.state.images;
        for(let i = 0; i < images.length; i++) {
            if(selectedImages.indexOf(images[i].imageID) === -1) {
                selectedImages.push(images[i].imageID);
            }
        }
        this.setState({selectedImages: selectedImages});
    }

    handleSelectInvert() {
        let selectedImages = this.state.selectedImages;
        let images = this.state.images;
        for(let i = 0; i < images.length; i++) {
            if(selectedImages.indexOf(images[i].imageID) === -1) {
                selectedImages.push(images[i].imageID);
            } else {
                selectedImages.splice(selectedImages.indexOf(images[i].imageID),1);
            }
        }
        this.setState({selectedImages: selectedImages});
    }

    handleDeselectAll() {
        this.setState({selectedImages: []});
    }

    focusCreateFolderReal() {
        if((document.getElementById("createFolderName") as HTMLInputElement)) {
            (document.getElementById("createFolderName") as HTMLInputElement).focus();
        }
    }

    focusCreateFolder() {
        setTimeout(this.focusCreateFolderReal.bind(this),800);
    }

    handleModifyFolderModalToggle() {
        this.setState({ modifyFolderModalOpen: !this.state.modifyFolderModalOpen },this.focusModifyFolder.bind(this));
    }

    focusModifyFolderReal() {
        if((document.getElementById("modifyFolderName") as HTMLInputElement)) {
            (document.getElementById("modifyFolderName") as HTMLInputElement).value = this.state.curFolderName;
            (document.getElementById("modifyFolderName") as HTMLInputElement).focus();
            (document.getElementById("modifyFolderName") as HTMLInputElement).select();
        }
    }

    focusModifyFolder() {
        setTimeout(this.focusModifyFolderReal.bind(this),800);
    }

    handleMoveFilesModalToggle() {
        this.setState({ moveFilesModalOpen: !this.state.moveFilesModalOpen });
    }

    handleDeleteFilesModalToggle() {
        this.setState({ deleteFilesModalOpen: !this.state.deleteFilesModalOpen });
    }

    handleFolderDeleteModalToggle() {
        this.setState({ deleteFolderModalOpen: !this.state.deleteFolderModalOpen });
    }

    handleCreateFolder() {
        let folderName = (document.getElementById("createFolderName") as HTMLInputElement).value;
        this.handleCreateFolderModalToggle();
        this.createFolder(folderName);
    }

    handleCreateFolderKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            e.preventDefault();
            this.handleCreateFolder();
        } else if(e.key === "Escape") {
            this.handleCreateFolderModalToggle();
        }
    }

    handleModifyFolderKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            e.preventDefault();
            this.handleModifyFolder();
        } else if(e.key === "Escape") {
            this.handleModifyFolderModalToggle();
        }
    }

    async createFolder(folderName:string) {
        let jsonurl = "https://api.deine.fans/api/folders";

       
            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
       

        jsonurl += "&category=images";

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: folderName
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder created successfully." });
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    handleModifyFolder() {
        let folderName = (document.getElementById("modifyFolderName") as HTMLInputElement).value;
        this.handleModifyFolderModalToggle();
        this.modifyFolder(folderName);
    }

    handleDeleteFolder() {
        this.handleFolderDeleteModalToggle();
        this.deleteFolder();
    }

    async modifyFolder(folderName:string) {
        let jsonurl = "https://api.deine.fans/api/folders/" + encodeURIComponent(this.state.curFolderGuid);


            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);


        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: folderName
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder modified successfully." });
            this.setState({ curFolderName: folderName })
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async deleteFolder() {
        let jsonurl = "https://api.deine.fans/api/folders/" + encodeURIComponent(this.state.curFolderGuid);


            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);


        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "DELETE"
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder deleted successfully." });
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async fetchFolders() {
        let jsonurl = "https://api.deine.fans/api/folders";



            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        

        jsonurl += "&category=images";

        let response: any = null;

        try {
            response = await fetch(jsonurl);
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            let data = await response.json();
            this.setState({ folders: data.folders });
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    handleMoveToFolderDropdownToggle() {
        this.setState({ moveToFolderDropdownOpen: !this.state.moveToFolderDropdownOpen });
    }

    handleMoveToFolderChange(folder:FolderData|null) {
        if(folder === null) {
            this.setState({ moveToFolderGuid: "", moveToFolderName: "" });
        } else {
            this.setState({ moveToFolderGuid: folder.guid, moveToFolderName: folder.folder });
        }
    }

    handleMoveFiles() {
        this.handleMoveFilesModalToggle();
        this.moveFiles();
    }

    handleDeleteFiles() {
        this.handleDeleteFilesModalToggle();
        this.deleteFiles();
    }

    async moveFiles() {
        let jsonurl = "https://api.deine.fans/api/images/move";



            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: this.state.moveToFolderGuid,
                    images: this.state.selectedImages
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }
        
        if (response.status === 200) {
            this.setState({ infoMessage: "Files moved successfully." });
            this.fetchData();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async deleteFiles() {
        let jsonurl = "https://api.deine.fans/api/images";



            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    images: this.state.selectedImages
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Files deleted successfully." });
            this.fetchData();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    fetchData = async () => {
        this.handleDeselectAll();

        this.setState({ fetching: true });
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        var jsonurl = "https://api.deine.fans/api/images";

        jsonurl += "?role=producer";


            jsonurl += "&producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        

        jsonurl += "&dimension=" + encodeURIComponent("320x240xinside");
        if(this.state.curPage > 0) {
            jsonurl += "&startindex=" + encodeURIComponent((this.state.curPage * this.state.pageSize));
        }
        jsonurl += "&sort=" + encodeURIComponent(this.state.sort);
        jsonurl += "&pagesize=" + encodeURIComponent(this.state.pageSize);
        jsonurl += "&folder=" + encodeURIComponent(this.state.curFolderGuid);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                   
                }
            }

            if (data.images) {
                this.setState({ images: data.images });
            }

            if(data.total_items) {
                this.handlePagination(data.total_items);
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    handlePagination(numItems:number) {
        if(numItems > 0) {
            let newArray:PageData[] = [];
            for(let si = 0;si < numItems;si += this.state.pageSize) {
                let newPage:PageData = {
                    page: Math.floor(si / this.state.pageSize),
                    active: Math.floor(si / this.state.pageSize) == this.state.curPage ? true : false
                };
                newArray.push(newPage);
            }
            this.setState({pagesArray: newArray});
        } else {
            this.setState({pagesArray: []});
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if( exp === "kostenfreieBilderHinzufuegen") {
                return "kostenfreie Bilder zum Post hinzufügen";
            }
            if( exp === "kostenpflichtigeBilderHinzufuegen") {
                return "kostenpflichtige Bilder zum Post hinzufügen";
            }
            if (exp === "ConfirmSelection") {
                return "Auswahl bestätigen";
            }
            if(exp === "SelectAll") {
                return "Alle auswählen";
            }
            if(exp === "SelectInvert") {
                return "Auswahl umkehren";
            }
            if(exp === "SelectNone") {
                return "Auswahl aufheben";
            }
            if (exp === "selected") {
                return "ausgewählt";
            }
            if (exp === "Move") {
                return "Verschieben";
            }
            if(exp === "MoveFiles") {
                return "Bilder verschieben";
            }
            if(exp === "DeleteFiles") {
                return "Bilder löschen";
            }
            if(exp === "MoveFilesToFolder") {
                return "Wähle unten den Zielordner aus, in den die Bilder verschoben werden sollen.";
            }
            if(exp === "SelectFolder") {
                return "Ordner auswählen";
            }
            if(exp === "DeleteFilesWarning") {
                return "Wenn die Bilder gelöscht werden, kann dies nicht rückgängig gemacht werden.";
            }
            if (exp === "Images") {
                return "Bilder";
            }
            if(exp === "Folders") {
                return "Ordner";
            }
            if(exp === "CreateFolder") {
                return "Ordner erstellen";
            }
            if(exp === "DeleteFolder") {
                return "Ordner löschen";
            }
            if(exp === "FolderName") {
                return "Ordnername";
            }
            if(exp === "Create") {
                return "Erstellen";
            }
            if(exp === "Modify") {
                return "Ändern";
            }
            if(exp === "Delete") {
                return "Löschen";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "DeleteFolderWarning") {
                return "Wenn der Ordner gelöscht wird, kann dies nicht rückgängig gemacht werden, alle Bilder in dem Ordner werder ebenfalls gelöscht. Bist Du sicher?";
            }
            if (exp === "RootDir") {
                return "Hauptordner";
            }
            if(exp === "RotateLeft") {
                return "90° nach links drehen";
            }
            if(exp === "RotateRight") {
                return "90° nach rechts drehen";
            }
            if(exp === "Rotate180") {
                return "180° drehen";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "No additional people") {
                return "Nur ich";
            }
            if(exp === "Additional people") {
                return "Andere Pers.";
            }
            if(exp === "uploadtime") {
                return "Zeitpunkt";
            }
            if(exp === "sortindex") {
                return "Eigene Sotierung";
            }
            if(exp === "dimensions") {
                return "Pixelauflösung";
            }
            if(exp === "uploadsize") {
                return "Dateigrösse";
            }
            if(exp === "reviewstatus") {
                return "Freischaltung";
            }
            if(exp === "adultstatus") {
                return "Alterseinstufung";
            }
        } else {
            if( exp === "kostenfreieBilderHinzufuegen") {
                return "Add free images"
            }
            if( exp === "kostenpflichtigeBilderHinzufuegen") {
                return "Add paid images";
            }
            if (exp === "ConfirmSelection") {
                return "Confirm selection";
            }
            if(exp === "SelectAll") {
                return "Select all";
            }
            if(exp === "SelectInvert") {
                return "Invert selection";
            }
            if(exp === "SelectNone") {
                return "Select none";
            }
            if (exp === "selected") {
                return "selected";
            }
            if (exp === "Move") {
                return "Move";
            }
            if(exp === "MoveFiles") {
                return "Move images";
            }
            if(exp === "DeleteFiles") {
                return "Delete images";
            }
            if(exp === "MoveFilesToFolder") {
                return "Select a folder below to move the images to."
            }
            if(exp === "SelectFolder") {
                return "Select folder";
            }
            if(exp === "DeleteFilesWarning") {
                return "If the images are deleted, this cannot be undone.";
            }
            if(exp === "CreateFolder") {
                return "Create folder";
            }
            if(exp === "DeleteFolder") {
                return "Delete folder";
            }
            if(exp === "FolderName") {
                return "Folder Name";
            }
            if(exp === "Create") {
                return "Create";
            }
            if(exp === "Modify") {
                return "Modify";
            }
            if(exp === "Delete") {
                return "Delete";
            }
            if(exp === "Folders") {
                return "Folders";
            }
            if (exp === "RootDir") {
                return "Root Folder";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "DeleteFolderWarning") {
                return "If the folder is deleted, this cannot be undone, all images in the folder will also be deleted. Are you sure?";
            }
            if(exp === "RotateLeft") {
                return "90° rotation to the left";
            }
            if(exp === "RotateRight") {
                return "90° rotation to the right";
            }
            if(exp === "Rotate180") {
                return "Rotate 180°";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if(exp === "No additional people") {
                return "Only Me";
            }
            if(exp === "Additional people") {
                return "Other people";
            }
            if(exp === "uploadtime") {
                return "Upload time";
            }
            if(exp === "sortindex") {
                return "Custom sort";
            }
            if(exp === "dimensions") {
                return "Pixel resolution";
            }
            if(exp === "uploadsize") {
                return "File size";
            }
            if(exp === "reviewstatus") {
                return "Review status";
            }
            if(exp === "adultstatus") {
                return "Adult status";
            }
        }

        return exp;
    }

    imageDeleteClick = async(imageID:string) => {
        var jsonurl = "https://api.deine.fans/api/image/delete/" + encodeURIComponent(imageID);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error deleting image1: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error deleting image2: " + data.error });
                } else {
                    this.setState({ infoMessage: data.message });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error deleting image3: " + error.toString() });
            this.setState({ fetching: false });
        }

        this.fetchData();
    }

    imageClicked(img:ImageData) {
        console.log("ImageList imageClicked");
        this.props.onImageClick(img);
    }

    confirmSelection() {
        if(this.state.selectedImages) {
            if(this.state.selectedImages.length > 0) {
                let imageDataArray:ImageData[] = [];

                for(let i = 0; i < this.state.selectedImages.length; i++) {
                    let newImage:ImageData|undefined = this.state.images.find((img:ImageData) => img.imageID === this.state.selectedImages[i]);
                    if(newImage !== null && newImage !== undefined) {
                        imageDataArray.push(newImage);
                    }
                }
                this.props.confirmSelection(imageDataArray);
                this.setState({isOpen: false});
                this.props.closeCallback();

            }
        }
    }

    uploadFinish(msg:string) {
        this.setState({infoMessage: msg});
        this.setState({errorMessage: ""});
        this.fetchData();
    }

    imageRotateLeft(img:ImageData) {
        this.doRotate(img,"Left");
    }

    imageRotateRight(img:ImageData) {
        this.doRotate(img,"Right");
    }

    imageRotate180(img:ImageData) {
        this.doRotate(img,"180");
    }

    doRotate = async(img:ImageData,rotation:string) => {
        if(img === null) {
            return;
        }

        this.setState({ singleImageOperation: true });

        var jsonurl = "https://api.deine.fans/api/image/Rotate/";

        jsonurl += encodeURIComponent(img.imageID);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        }

        jsonurl += "&rotate=" + encodeURIComponent(rotation);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();
            this.setState({ singleImageOperation: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                return;
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                  
                }
            }

            if ((data.error === null || data.error === "") && data.tempGuid !== "") {
                let tempGuid:string = data.tempGuid;

                if(tempGuid === null || tempGuid === "") {
                    return;
                }

                this.saveImageModification(img,tempGuid);
            } else {
                this.setState({ errorMessage: data.error });
                alert(data.error);
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleImageOperation: false });
        }
    }

    saveImageModification = async(img:ImageData,tempGuid:string) => {
        if (tempGuid !== "") {
            if(img === null) {
                return;
            }
    
            this.setState({ singleImageOperation: true });
    
            var jsonurl = "https://api.deine.fans/api/image/Save/";
    
            jsonurl += encodeURIComponent(img.imageID);
    
            if (this.context) {
                jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
            }
    
            jsonurl += "&tempGuid=" + encodeURIComponent(tempGuid);
    
            let response: any = null;
    
            try {
                response = await fetch(jsonurl,{method: "GET"});
                let data = await response.json();
                this.setState({ singleImageOperation: false });
    
                if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                    this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                    return;
                } else {
                    if (data.error !== "" && data.error !== null) {
                        this.setState({ errorMessage: "Error: " + data.error });
                        
                    }
                }
    
                if (data.error === null || data.error === "") {
                    if (data.new320x240xinsideurl !== null) {
                        console.log("New image url: " + data.new320x240xinsideurl);
                        console.log("Setting new image url: " + data.new320x240xinsideurl + " for imageID: " + data.imageID);
                        let img = document.getElementById("img-" + data.imageID);
                        if(img !== null) {
                            console.log("Setting new image url: " + data.new320x240xinsideurl + " for imageID: " + data.imageID);
                            img.setAttribute("src", data.new320x240xinsideurl);
                        } else {
                            console.log("Image not found: " + data.imageID);
                        }
                    }
                } else {
                    this.setState({ errorMessage: data.error });
                    alert(data.error);
                }
            } catch (error: any) {
                this.setState({ errorMessage: "Error fetching data: " + error.toString() });
                this.setState({ singleImageOperation: false });
            }
        }
    }

    render() {
        return (
          <div id="ImagesListDIV">
            <Modal enforceFocus={true} isOpen={this.state.createFolderModalOpen}>
              <ModalHeader>
                <span>{this.getText("CreateFolder")}</span>
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="folderName">{this.getText("FolderName")}</Label>
                    <Input
                      type="text"
                      name="createFolderName"
                      id="createFolderName"
                      placeholder={this.getText("FolderName")}
                      onKeyPress={this.handleCreateFolderKeyPress.bind(this)}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={this.handleCreateFolder.bind(this)}
                >
                  {this.getText("Create")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.handleCreateFolderModalToggle.bind(this)}
                >
                  {this.getText("Cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.modifyFolderModalOpen}>
              <ModalHeader>
                <span>
                  {this.getText("ModifyFolder")} {this.state.curFolderName}
                </span>
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="folderName">{this.getText("FolderName")}</Label>
                    <Input
                      type="text"
                      name="modifyFolderName"
                      id="modifyFolderName"
                      placeholder={this.getText("FolderName")}
                      onKeyPress={this.handleModifyFolderKeyPress.bind(this)}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={this.handleModifyFolder.bind(this)}
                >
                  {this.getText("Modify")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.handleModifyFolderModalToggle.bind(this)}
                >
                  {this.getText("Cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.deleteFolderModalOpen}>
              <ModalHeader>
                <span>
                  {this.getText("DeleteFolder")} {this.state.curFolderName}
                </span>
              </ModalHeader>
              <ModalBody>
                <Alert color="warning">
                  {this.getText("DeleteFolderWarning")}
                </Alert>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={this.handleDeleteFolder.bind(this)}
                >
                  {this.getText("Delete")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.handleFolderDeleteModalToggle.bind(this)}
                >
                  {this.getText("Cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.deleteFilesModalOpen}>
              <ModalHeader>
                <span>
                  {this.getText("DeleteFiles")}{" "}
                  <span className="badge bg-secondary">
                    {this.state.selectedImages.length}{" "}
                    {this.getText("selected")}
                  </span>
                </span>
              </ModalHeader>
              <ModalBody>
                <Alert color="warning">
                  {this.getText("DeleteFilesWarning")}
                </Alert>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={this.handleDeleteFiles.bind(this)}
                >
                  {this.getText("DeleteFiles")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.handleDeleteFilesModalToggle.bind(this)}
                >
                  {this.getText("Cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.moveFilesModalOpen}>
              <ModalHeader>
                <span>
                  {this.getText("MoveFiles")}{" "}
                  <span className="badge bg-secondary">
                    {this.state.selectedImages.length}{" "}
                    {this.getText("selected")}
                  </span>
                </span>
              </ModalHeader>
              <ModalBody>
                <p>{this.getText("MoveFilesToFolder")}</p>
                <FormGroup>
                  <Label>{this.getText("SelectFolder")}</Label>
                  <Dropdown
                    isOpen={this.state.moveToFolderDropdownOpen}
                    toggle={this.handleMoveToFolderDropdownToggle.bind(this)}
                    direction="down"
                  >
                    <DropdownToggle size="sm" caret>
                      {this.state.moveToFolderName !== ""
                        ? this.state.moveToFolderName
                        : this.getText("RootDir")}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={
                          this.state.moveToFolderGuid === "" &&
                          this.state.folders && this.state.folders.length > 0
                            ? "bg-secondary text-white"
                            : ""
                        }
                        onClick={this.handleMoveToFolderChange.bind(this, null)}
                      >
                        {this.getText("RootDir")}
                      </DropdownItem>
                      {this.state.folders
                        ? this.state.folders.map((folder, index) => (
                            <DropdownItem
                              className={
                                folder.guid === this.state.moveToFolderGuid
                                  ? "bg-secondary text-white"
                                  : ""
                              }
                              onClick={this.handleMoveToFolderChange.bind(
                                this,
                                folder
                              )}
                            >
                              {folder.folder}
                            </DropdownItem>
                          ))
                        : null}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="warning"
                  onClick={this.handleMoveFiles.bind(this)}
                >
                  {this.getText("MoveFiles")}
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.handleMoveFilesModalToggle.bind(this)}
                >
                  {this.getText("Cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Alert isOpen={this.state.infoMessage !== ""} color="info">
              {this.state.infoMessage}
            </Alert>
            <Alert isOpen={this.state.errorMessage !== ""} color="danger">
              {this.state.errorMessage}
            </Alert>
            {this.state.isMobile ? (
              <ImageUploadNoCrop
                producerID={this.context.loginData!.producerID}
                uploadInfo="image"
                finishCallback={this.uploadFinish.bind(this)}
              />
            ) : (
              <div className="row">
                <div className="col-4">
                  <ImageUploadMultiple
                    producerID={this.context.loginData!.producerID}
                    uploadInfo="image"
                    finishCallback={this.uploadFinish.bind(this)}
                  />
                </div>
                <div className="col-8">
                  <ImageUploadDragAndDrop
                    producerID={this.context.loginData!.producerID}
                    uploadInfo="image"
                    finishCallback={this.uploadFinish.bind(this)}
                  />
                </div>
                <hr />
              </div>
            )}
            <div id="ImagesListPane">
              <Row className="mb-2">
                <Col
                  xs="12"
                  lg={this.state.modalMode ? 4 : 6}
                  className="paginationColumnLeft"
                >
                  {this.state.pagesArray ? (
                    this.state.pagesArray.length > 0 ? (
                      <Pagination size="sm">
                        {this.state.curPage > 0 ? (
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.handlePageChange.bind(
                                this,
                                this.state.curPage - 1
                              )}
                              style={{ color: "#000" }}
                            >
                              &laquo;
                            </PaginationLink>
                          </PaginationItem>
                        ) : null}
                        {this.state.pagesArray.map((page, index) => (
                          <PaginationItem
                            style={{ display: "list-item" }}
                            active={page.active}
                            className={
                              page.page < this.state.maxPages ? "" : "d-none"
                            }
                          >
                            <PaginationLink
                              onClick={this.handlePageChange.bind(
                                this,
                                page.page
                              )}
                              style={{ color: "#000" }}
                            >
                              {page.page + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        {this.state.curPage + 1 <
                        this.state.pagesArray.length ? (
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.handlePageChange.bind(
                                this,
                                this.state.curPage + 1
                              )}
                              style={{ color: "#000" }}
                            >
                              &raquo;
                            </PaginationLink>
                          </PaginationItem>
                        ) : null}
                        {this.state.pagesArray.length >
                        this.state.pagesToShow ? (
                          <Dropdown
                            className="paginationExtraDropdown"
                            isOpen={this.state.extraPagesDropdownOpen}
                            toggle={this.handleExtraPagesDropdownToggle.bind(
                              this
                            )}
                            direction="down"
                          >
                            <DropdownToggle size="sm" caret>
                              {this.state.curPage >= this.state.maxPages
                                ? this.state.curPage
                                : this.getText("More")}
                            </DropdownToggle>
                            <DropdownMenu>
                              {this.state.pagesArray.map((page, index) => (
                                <DropdownItem
                                  className={
                                    page.page >= this.state.maxPages
                                      ? page.active
                                        ? "bg-secondary text-white"
                                        : ""
                                      : "d-none"
                                  }
                                  onClick={this.handlePageChange.bind(
                                    this,
                                    page.page
                                  )}
                                >
                                  {page.page + 1}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        ) : null}
                      </Pagination>
                    ) : null
                  ) : null}
                </Col>
                <Col
                  xs="12"
                  lg={this.state.modalMode ? 8 : 6}
                  className="paginationColumnRight"
                >
                  <Dropdown
                    style={{ display: "inline-block", marginRight: "10px" }}
                    isOpen={this.state.pageSizeDropdownOpen}
                    toggle={this.handlePageSizeDropdownToggle.bind(this)}
                    direction="down"
                  >
                    <DropdownToggle size="sm" caret>
                      {this.getText("PageSize")} {this.state.pageSize}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 12)}
                      >
                        12
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 24)}
                      >
                        24
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 36)}
                      >
                        36
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 48)}
                      >
                        48
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 60)}
                      >
                        60
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 72)}
                      >
                        72
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handlePageSizeChange.bind(this, 100)}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    style={{ display: "inline-block", marginRight: "10px" }}
                    isOpen={this.state.sortDropdownOpen}
                    toggle={this.handleSortDropdownToggle.bind(this)}
                    direction="down"
                  >
                    <DropdownToggle size="sm" caret>
                      {this.getText("Sort")} {this.getText(this.state.sort)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(this, "uploadtime")}
                      >
                        {this.getText("uploadtime")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(this, "dimensions")}
                      >
                        {this.getText("dimensions")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(this, "uploadsize")}
                      >
                        {this.getText("uploadsize")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(
                          this,
                          "reviewstatus"
                        )}
                      >
                        {this.getText("reviewstatus")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(
                          this,
                          "adultstatus"
                        )}
                      >
                        {this.getText("adultstatus")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSortChange.bind(this, "ranking")}
                      >
                        {this.getText("ranking")}
                      </DropdownItem>
                      <DropdownItem
                        className="d-none"
                        onClick={this.handleSortChange.bind(this, "sortindex")}
                      >
                        {this.getText("sortindex")}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    size="sm"
                    className="refreshbtn"
                    onClick={this.fetchData.bind(this)}
                  >
                    <FontAwesomeIcon icon={solid("sync")} fixedWidth />{" "}
                    {this.getText("Refresh")}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="6" className="paginationColumnLeft">
                  <h5>
                    {this.getText("Images")}&nbsp;
                    <Dropdown
                      style={{ display: "inline-block", marginRight: "0px" }}
                      isOpen={this.state.foldersDropdownOpen}
                      toggle={this.handleFoldersDropdownToggle.bind(this)}
                      direction="down"
                    >
                      <DropdownToggle size="sm" caret>
                        {this.state.curFolderName !== ""
                          ? this.state.curFolderName
                          : this.getText("RootDir")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          className={
                            this.state.curFolderGuid === "" &&
                            this.state.folders && this.state.folders.length > 0
                              ? "bg-secondary text-white"
                              : ""
                          }
                          onClick={this.handleFolderChange.bind(this, null)}
                        >
                          {this.getText("RootDir")}
                        </DropdownItem>
                        {this.state.folders
                          ? this.state.folders.map((folder, index) => (
                              <DropdownItem
                                className={
                                  folder.guid === this.state.curFolderGuid
                                    ? "bg-secondary text-white"
                                    : ""
                                }
                                onClick={this.handleFolderChange.bind(
                                  this,
                                  folder
                                )}
                              >
                                {folder.folder}
                              </DropdownItem>
                            ))
                          : null}
                      </DropdownMenu>
                    </Dropdown>
                    {this.state.curFolderGuid !== "" ? (
                      <Button
                        size="sm"
                        color="secondary"
                        style={{ display: "inline-block" }}
                        onClick={this.handleFolderChange.bind(this, null)}
                      >
                        <FontAwesomeIcon
                          icon={faFolderArrowUp}
                        ></FontAwesomeIcon>
                      </Button>
                    ) : null}
                    <Button
                      style={{ display: "inline-block", marginLeft: "10px" }}
                      size="sm"
                      color="secondary"
                      onClick={this.handleCreateFolderModalToggle.bind(this)}
                    >
                      <FontAwesomeIcon icon={faFolderPlus} />
                    </Button>
                    {this.state.curFolderGuid !== "" ? (
                      <Button
                        size="sm"
                        color="secondary"
                        style={{ display: "inline-block", marginLeft: "10px" }}
                        onClick={this.handleModifyFolderModalToggle.bind(this)}
                      >
                        <FontAwesomeIcon icon={faFolderCog}></FontAwesomeIcon>
                      </Button>
                    ) : null}
                    {this.state.curFolderGuid !== "" ? (
                      <Button
                        size="sm"
                        color="secondary"
                        style={{ display: "inline-block", marginLeft: "10px" }}
                        onClick={this.handleFolderDeleteModalToggle.bind(this)}
                      >
                        <FontAwesomeIcon icon={faFolderXmark} />
                      </Button>
                    ) : null}
                  </h5>
                </Col>
                <Col xs="12" md="6" className="paginationColumnRight">
                  <Dropdown
                    style={{ display: "inline-block", marginRight: "0px" }}
                    isOpen={this.state.fileSelectionDropdownOpen}
                    toggle={this.handleFileSelectionDropdownToggle.bind(this)}
                    direction="down"
                  >
                    <DropdownToggle size="sm" caret>
                      {this.state.selectedImages.length}{" "}
                      {this.getText("selected")}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={this.handleSelectAll.bind(this)}>
                        {this.getText("SelectAll")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.handleSelectInvert.bind(this)}
                      >
                        {this.getText("SelectInvert")}
                      </DropdownItem>
                      <DropdownItem onClick={this.handleDeselectAll.bind(this)}>
                        {this.getText("SelectNone")}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    disabled={
                      this.state.selectedImages.length === 0 ? true : false
                    }
                    size="sm"
                    color="secondary"
                    style={{ display: "inline-block", marginLeft: "10px" }}
                    onClick={this.handleMoveFilesModalToggle.bind(this)}
                  >
                    <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>{" "}
                    {this.getText("Move")}
                  </Button>
                  <Button
                    disabled={
                      this.state.selectedImages.length === 0 ? true : false
                    }
                    size="sm"
                    color="secondary"
                    style={{ display: "inline-block", marginLeft: "10px" }}
                    onClick={this.handleDeleteFilesModalToggle.bind(this)}
                  >
                    <FontAwesomeIcon icon={faFileXmark}></FontAwesomeIcon>{" "}
                    {this.getText("Delete")}
                  </Button>
                </Col>
              </Row>

              {!this.state.singleSelect &&
              this.state.mediaAddMode !== "" &&
              this.state.selectedImages.length > 0 ? (
                <p className="text-center mt-2">
                  <Button
                    size="lg"
                    color="primary"
                    onClick={this.confirmSelection.bind(this)}
                  >
                    {this.state.selectedImages.length}{" "}
                    {this.state.mediaAddMode === "free"
                      ? this.getText("kostenfreieBilderHinzufuegen")
                      : this.getText("kostenpflichtigeBilderHinzufuegen")}
                  </Button>
                </p>
              ) : null}

              {this.state.fetching ? (
                <p className="pt-4">
                  <FontAwesomeIcon icon={solid("cog")} spin size="4x" />
                </p>
              ) : (
                <div className="mt-2 d-flex flex-wrap overflow" style={{ gap: "4px" }}>
                  {this.state.images.map((img, index) => (
                    <div
                    key={`image-${index}`}
                      id={"imageList-" + index.toString()}
                      className="imageItem ml-1 mb-1"
                    >
                      <div className="d-flex justify-content-between w-100">
                          <input
                            type="checkbox"
                            className="imageSelectCheckBox checkmark"
                            checked={this.state.selectedImages.includes(
                              img.imageID
                            )}
                            onChange={this.handleImageSelect.bind(
                              this,
                              img.imageID
                            )}
                          />
                        <small
                          onClick={this.handleImageSelect.bind(
                            this,
                            img.imageID
                          )}
                        >
                          {DateTime.fromISO(
                            img.uploadTime.toString()
                          ).toLocaleString(DateTime.DATETIME_MED)}
                        </small>
                        
                        <div>
                          {this.state.mediaAddMode === "" ? (
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              onClick={this.imageClicked.bind(this, img)}
                              icon={faMagnifyingGlass}
                              fixedWidth
                            />
                          ) : null}
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={this.imageDeleteClick.bind(
                              this,
                              img.imageID
                            )}
                            icon={faRemove}
                            fixedWidth
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <img
                          id={"img-" + img.imageID}
                          style={{ cursor: "pointer" }}
                          onClick={
                            this.state.singleSelect
                              ? this.imageClicked.bind(this, img)
                              : this.handleImageSelect.bind(this, img.imageID)
                          }
                          src={img.imageURL}
                          className="w-100"
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <small>
                        {img.reviewStatus === 1 ? <span className='badge bg-success'><FontAwesomeIcon icon={solid("check-circle")}></FontAwesomeIcon> {this.getText("Reviewed")}</span> : <span className='badge bg-info'>{this.getText("In review")}</span>}
                        </small>
                        {img.isProfilePic ? (
                          <small>
                            <FontAwesomeIcon
                              icon={solid("user-circle")}
                            ></FontAwesomeIcon>{""}
                            {this.getText("ProfilePic")}&nbsp;
                          </small>
                        ) : null}
                        {img.reviewStatus === 1 ? (
                          img.adultRating === 0 || img.adultRating === 2 ? (
                            <small>{this.getText("Soft")}&nbsp;</small>
                          ) : img.adultRating === 1 ? (
                            <small>{this.getText("AVS")}&nbsp;</small>
                          ) : (
                            <small>{this.getText("Unbestimmt")}&nbsp;</small>
                          )
                        ) : img.additionalPeople === null ||
                          img.additionalPeople === "" ? (
                          <small
                            style={{ cursor: "pointer" }}
                            onClick={this.imageClicked.bind(this, img)}
                          >
                            <FontAwesomeIcon
                              icon={solid("user")}
                            ></FontAwesomeIcon>{" "}
                            {this.getText("No additional people")}&nbsp;
                          </small>
                        ) : (
                          <small
                            style={{ cursor: "pointer" }}
                            onClick={this.imageClicked.bind(this, img)}
                          >
                            <FontAwesomeIcon
                              icon={solid("users-rectangle")}
                            ></FontAwesomeIcon>{" "}
                            {this.getText("Additional People")}&nbsp;
                          </small>
                        )}
                        <div>
                          {this.state.singleImageOperation ? (
                            <FontAwesomeIcon
                              icon={solid("cog")}
                              spin
                              fixedWidth
                            />
                          ) : null}
                          {!this.state.singleImageOperation ? (
                            <span
                              style={{ cursor: "pointer" }}
                              id={"imageRotateLeft-" + index.toString()}
                              onClick={this.imageRotateLeft.bind(this, img)}
                            >
                              <FontAwesomeIcon icon={solid("rotate-left")} />
                            </span>
                          ) : null}
                          &nbsp;
                          {!this.state.singleImageOperation ? (
                            <span
                              style={{ cursor: "pointer" }}
                              id={"imageRotateRight-" + index.toString()}
                              onClick={this.imageRotateRight.bind(this, img)}
                            >
                              <FontAwesomeIcon icon={solid("rotate-right")} />
                            </span>
                          ) : null}
                          &nbsp;
                          {!this.state.singleImageOperation ? (
                            <span
                              style={{ cursor: "pointer" }}
                              id={"imageRotate180-" + index.toString()}
                              onClick={this.imageRotate180.bind(this, img)}
                            >
                              <FontAwesomeIcon icon={solid("rotate")} />
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {!this.state.singleImageOperation ? (
                        <UncontrolledTooltip
                          placement="top"
                          target={"imageRotateLeft-" + index.toString()}
                        >
                          {this.getText("RotateLeft")}
                        </UncontrolledTooltip>
                      ) : null}
                      {!this.state.singleImageOperation ? (
                        <UncontrolledTooltip
                          placement="top"
                          target={"imageRotateRight-" + index.toString()}
                        >
                          {this.getText("RotateRight")}
                        </UncontrolledTooltip>
                      ) : null}
                      {!this.state.singleImageOperation ? (
                        <UncontrolledTooltip
                          placement="top"
                          target={"imageRotate180-" + index.toString()}
                        >
                          {this.getText("Rotate180")}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
    }
}