import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { LocalStorageWorker } from "./StorageHelper";
import { ThemeContext } from './ThemeContext';

interface SupportModalProps {
    show: boolean;
    handleClose: () => void;
    handleSubmit: (formData: { name: string; email: string; message: string }) => void;
    language: string;
    producerID: string;
}

const SupportModal: React.FC<SupportModalProps> = ({ show, handleClose, handleSubmit, language, producerID }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [apiTexts, setApiTexts] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null); // Feedback state
    const [apiLoading, setApiLoading] = useState(false); // For the loading indicator

    let myLocalStorage = new LocalStorageWorker();

    const fetchApiText = async (key: string) => {
        setApiLoading(true);
        try {
            const cacheKey = `${key}_${language}`;
            const cachedText = localStorage.getItem(cacheKey);
            if (cachedText) {
                setApiTexts((prev) => ({ ...prev, [key]: cachedText }));
                return;
            }

            const newLang = language === "auto" ? "de" : language;
            const response = await fetch(`https://vhsue2beba.execute-api.eu-west-1.amazonaws.com/api/${key}?culture=${newLang}`);
            if (!response.ok) {
                throw new Error('Failed to fetch API text!');
            }

            let data = await response.text();
            if (data.startsWith('"') && data.endsWith('"')) {
                data = data.slice(1, -1);
            }

            localStorage.setItem(cacheKey, data);
            setApiTexts((prev) => ({ ...prev, [key]: data }));
        } catch (error) {
            console.error(`Error fetching API text for key ${key}:`, error);
            setApiTexts((prev) => ({ ...prev, [key]: key }));
        } finally {
            setApiLoading(false);
        }
    };

    const textFromApi = (key: string): string => {
        if (!apiTexts[key] && !apiLoading) {
            fetchApiText(key);
        }
        return apiTexts[key] || 'Loading...';
    };

    useEffect(() => {
        if (show) {
            fetchApiText('Support');
        }
    }, [show, language, producerID]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true); // Show loading indicator during submission

        try {
            const jwtResponse = await fetch(`https://fanapi-test.guppy.live/api/tokens?producerID=${producerID}&token=${myLocalStorage.get("authtoken")}&scope=sendertoolsupportticket`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!jwtResponse.ok) {
                throw new Error('Failed to exchange token for JWT');
            }

            const jwtData = await jwtResponse.json();
            const jwt = jwtData.authToken;

            const sendTicketResponse = await fetch(`https://are2mh0sh1.execute-api.eu-west-1.amazonaws.com/api/ticket?text=${formData.message}&postURL=${window.location.href}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!sendTicketResponse.ok) {
                throw new Error('Failed to submit support request');
            }

            setFeedbackMessage('Your support request has been submitted successfully!'); // Show feedback
            setTimeout(() => {
                setFeedbackMessage(null); // Reset feedback
                handleClose(); // Close modal after 3 seconds
            }, 3000);

            const sendTicketResult = await sendTicketResponse.json();
            console.log('Form submission successful:', sendTicketResult);

            handleSubmit(formData);
        } catch (error) {
            console.error('Error during form submission:', error);
            setFeedbackMessage('Failed to submit support request. Please try again.');
            setTimeout(() => setFeedbackMessage(null), 3000);
        } finally {
            setLoading(false); // Hide loading indicator
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header
                        closeButton
                        style={{
                            backgroundColor: theme.mainBackgroundColor,
                            color: theme.mainTextColor,
                        }}
                    >
                        <Modal.Title>{textFromApi('Support')}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={onSubmit}>
                        <Modal.Body
                            style={{
                                backgroundColor: theme.mainBackgroundColor,
                                color: theme.mainTextColor,
                            }}
                        >
                            <p>{textFromApi('inChat_supportModal')}</p>

                            {feedbackMessage && (
                                <div
                                    style={{
                                        padding: '10px',
                                        marginBottom: '10px',
                                        color: '#fff',
                                        backgroundColor: feedbackMessage.includes('success') ? '#28a745' : '#dc3545',
                                        borderRadius: '5px',
                                    }}
                                >
                                    {feedbackMessage}
                                </div>
                            )}

                            {loading && (
                                <div style={{ textAlign: 'center', margin: '10px 0' }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            )}

                            <Form.Group controlId="formMessage" className="mt-3">
                                <Form.Label>{textFromApi('Message')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder={textFromApi('EnterMessageHere')}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                backgroundColor: theme.mainBackgroundColor,
                                color: theme.mainTextColor,
                            }}
                        >
                            <Button variant="secondary" onClick={handleClose} disabled={loading}>
                                Close
                            </Button>
                            <Button variant="success" type="submit" disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </ThemeContext.Consumer>
    );
};

export default SupportModal;
