import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { LovenseBasicSdk, LovenseSdkOptions, LovenseDevice, LovenseDeviceInfo, LovenseQrCodeInfo } from './lovenseSdkTypes';
import axios from 'axios';
import LovenseDeviceDisplay from './LovenseDeviceDisplay';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBluetooth, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { ButtonGroup } from 'react-bootstrap';
import LovenseDeviceSelect from './Lovense';
import Tooltip from 'react-bootstrap/Tooltip';
import LocalizedText from './LocalizedText';

export interface LovenseModalProps {
    closeCallback: () => void;
    onSelect: (device: LovenseDevice) => void;
    onChangeAllToysMode: (newMode: boolean) => void;
    language: string;
    producerID: string;
    pseudo: string;
    allToysMode: boolean;
}

interface LovenseModal {
    state: LovenseModalState;
    props: LovenseModalProps;
    closeCallback: () => void;
}

interface LovenseModalState {
    language: string;
    producerID: string;
    pseudo: string;
    devices: LovenseDevice[];
    initializing: boolean;
    fetching: boolean;
    lovenseAppConnected: boolean;
    logMessages: string[];
    toyOnline: boolean;
    qrCode: LovenseQrCodeInfo | null;
    deviceInfo: LovenseDeviceInfo | null;
    oldMode: boolean;
    allToysMode: boolean;
}

declare global {
    interface Window {
        lovenseSdk: LovenseBasicSdk;
        initializeLovense(authToken: string, uid: string): void;
    }
}

class LovenseModal extends React.Component {
    static contextType = AppContext;

    constructor(props: LovenseModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;

        this.state = {
            language: props.language,
            producerID: props.producerID,
            pseudo: props.pseudo,
            devices: [],
            initializing: true,
            fetching: false,
            lovenseAppConnected: false,
            logMessages: [],
            toyOnline: false,
            qrCode: null,
            deviceInfo: null,
            oldMode: false,
            allToysMode: props.allToysMode
        }

        library.add(faSpinner, faArrowsRotate, faBluetooth);
    }

    componentDidMount() {
        console.log("LovenseModal componentDidMount -> " + typeof (window.initializeLovense));
        this.initializeLovenseApi();
    }

    async initializeLovenseApi() {
        try {
            const result = await axios.post(
                "https://api.guppy.cloud/lovense/api/token/" + this.state.producerID
            );
    
            console.log("LovenseModal initializeLovenseApi-Token", result.data.token);
    
            if (result.data && result.data.token) {
                console.log("Lovense authToken", result.data.token);
                window.initializeLovense(result.data.token, this.state.producerID);
    
                window.lovenseSdk.on('ready', this.lovenseSdkReady.bind(this));
                window.lovenseSdk.on('appStatusChange', this.appStatusChange.bind(this));
                window.lovenseSdk.on('toyInfoChange', this.toyInfoChange.bind(this));
                window.lovenseSdk.on('toyOnlineChange', this.toyOnlineChange.bind(this));
                window.lovenseSdk.on('deviceInfoChange', this.deviceInfoChange.bind(this));
            } else {
                console.log("Lovense error", result.data);
                alert("Lovense initialization failed: " + (result.data.message || "Unexpected response format"));
            }
        } catch (error) {
            console.error("Error during Lovense initialization:", error);
        }
    }
    

    lovenseSdkReady = async (instance: LovenseBasicSdk) => {
        console.log("LovenseModal lovenseSdkReady");
        this.state.logMessages.push("lovenseSdkReady");
        this.setState({ initializing: false });
        this.toggleQrCode();
        this.checkAppStatus();
    }

    appStatusChange(status: boolean) {
        console.log("LovenseModal appStatusChange", status);
        this.state.logMessages.push("appStatusChange: " + status);
        this.setState({ lovenseAppConnected: status });
    }

    toyInfoChange(toyInfo: LovenseDevice[]) {
        this.state.logMessages.push("toyInfoChange: " + JSON.stringify(toyInfo));
        console.log("LovenseModal toyInfoChange", toyInfo);
        this.setState({ devices: toyInfo });
    }

    toyOnlineChange(status: boolean) {
        this.state.logMessages.push("toyOnlineChange: " + status);
        console.log("LovenseModal toyOnlineChange", status);
        this.setState({ toyOnline: status });
    }

    deviceInfoChange(device: LovenseDeviceInfo) {
        console.log("LovenseModal deviceInfoChange", device);
        this.setState({ deviceInfo: device });
    }

    updateToys = async () => {
        this.setState({ fetching: true });
        let myToys: LovenseDevice[] = await window.lovenseSdk.getOnlineToys();
        this.setState({ fetching: false });
        console.log("LovenseModal updateToys", myToys);

        this.setState({ devices: myToys });
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "ControlAllToys") {
                return "Alle Toys gleichzeitig steuern";
            }
            if (exp === "ConnectLovenseSmartphone") {
                return "Du kannst Deine Lovense-Toys per iOS oder Android App verbinden, klicke dazu auf App verbinden und scanne den QR-Code in der Lovense-App auf Deinem Smartphone ein. Alternativ kannst Du Deine Toys auch mit Deinem PC verbinden, wenn Du ein Dongle und die Lovense Connect Softwar hast."
            }
            if (exp === "Select") {
                return "Wähle";
            }
            if (exp === "Connect Smartphone App") {
                return "iOS/Android App verbinden";
            }
            if (exp === "Lovense device detection") {
                return "Lovense Gerät ermitteln";
            }
            if (exp === "Discovering lovense devices via LAN") {
                return "Lovense Gerät per LAN-Anschluss ermitteln";
            }
            if (exp === "Discovering locally connected lovense devices") {
                return "Lokal angeschlossenes Lovense Gerät ermitteln";
            }
            if (exp === "Change device") {
                return "Gerät wechseln";
            }
            if (exp === "Locally Connected") {
                return "Lokal per Dongle";
            }
            if (exp === "Connected via LAN") {
                return "Suche im Netzwerk";
            }
            if (exp === "You need Lovense Connect for this to work") {
                return "Du benötigst die Lovense Connect Software und der Lush muss in der Lovense Connect Software als Connected angezeigt werden.";
            }
            if (exp === "Show Devices") {
                return "Geräte anzeigen";
            }
            if (exp === "Old methods to connect") {
                return "Falls Dein Lush nicht angezeigt wird, wenn Du auf den Button Geräte anzeigen klickst, kannst Du die Buttons unten versuchen um Dich zu verbinden."
            }
            if (exp === "Show old methods") {
                return "Mein Lush wird nicht angezeigt, wenn ich auf Geräte anzeigen klicke";
            }
            if (exp === "Use Smartphone App") {
                return "Smartphone App verwenden";
            }
            if (exp === "Old Mode") {
                return "Mit PC verbinden";
            }

            if (exp === "SetAllToysMode") {
                return "Alle Toys gleichzeitig steuern";
            }

            if (exp === "Use App Version") {
                return "Mit App verbinden";
            }

            return exp;
        } else {
            if (exp === "ControlAllToys") {
                return "Control all toys at once";
            }
            if (exp === "ConnectLovenseSmartphone") {
                return "You can connect your Lovense toys via iOS or Android app, click on Connect App and scan the QR code in the Lovense app on your smartphone. Alternatively, you can also connect your toys to your PC if you have a dongle and the Lovense Connect software."
            }
            if (exp === "Connect Smartphone App") {
                return "Connect iOS/Android App";
            }
            if (exp === "You need Lovense Connect for this to work") {
                return "You need the Lovense Connect software for this to work, and the Lush must be connected in the Lovense Connect software.";
            }
            if (exp === "Old methods to connect") {
                return "If your Lush is not shown when you click on the button Show Devices, you can try the buttons below to connect."
            }
            if (exp === "Show old methods") {
                return "My Lush is not shown when clicking on Show Devices";
            }
            if (exp === "Use Smartphone App") {
                return "Use Smartphone App";
            }
            if (exp === "Old Mode") {
                return "Use PC Software";
            }
            if (exp === "SetAllToysMode") {
                return "Control all toys at once";
            }

            if (exp === "Use App Version") {
                return "Use App Version";
            }

            return exp;
        }
    }

    selectLovenseDevice(device: LovenseDevice) {
        console.log("LovenseModal selectLovenseDevice", device);
        this.props.onSelect(device);
    }

    checkAppStatus = async () => {
        if (!this.state.initializing) {
            let isConnected: boolean = false;
            isConnected = await window.lovenseSdk.getAppStatus();
            console.log("LovenseModal checkAppStatus", isConnected);
            this.setState({ lovenseAppConnected: !isConnected });
        }
    }

    toggleQrCode = async () => {
        if (this.state.qrCode) {
            this.setState({ qrCode: null });
        } else {
            let myQrCode: LovenseQrCodeInfo | null = await window.lovenseSdk.getQrcode();
            this.setState({ qrCode: myQrCode });
        }
    }

    switchAllToys = (event: FormEvent<HTMLInputElement>) => {
        console.log("LovenseModal switchAllToys", event.currentTarget.checked);
        this.setState({ allToysMode: event.currentTarget.checked });
    }

    clickAllToysMode = () => {
        this.props.onChangeAllToysMode(this.state.allToysMode);
    }

    getDeviceInfo = async () => {
        let myDeviceInfo: LovenseDeviceInfo = await window.lovenseSdk.getDeviceInfo();
        console.log("LovenseModal getDeviceInfo", myDeviceInfo);
        this.setState({ deviceInfo: myDeviceInfo });
        this.state.logMessages.push("deviceInfo: " + JSON.stringify(myDeviceInfo));
    }

    toggleOldMode = () => {
        this.setState({ oldMode: !this.state.oldMode });
    }

    onSelectOldDevice(domain: string, httpPort: number, httpsPort: number, toyID: string) {
        console.log("LovenseModal onSelectOldDevice", domain, httpPort, httpsPort, toyID);
        this.props.onSelect({ id: toyID, name: domain, nickname: toyID, connected: true, toyType: "old", fVersion: httpsPort, hVersion: "old", battery: 0 });
    }

    render() {
        return (
            <Modal show={true} onHide={this.closeCallback.bind(this)} centered>
            <Modal.Header closeButton className="border-0" style={{ backgroundColor: '#1b1c2a' }}>
              <Modal.Title className="fs-5 fw-bold text-white">Lovense</Modal.Title>
            </Modal.Header>
          
            <Modal.Body style={{ backgroundColor: '#1b1c2a', color: '#ffffff' }}>
              {(!this.state.lovenseAppConnected || !this.state.deviceInfo?.platform) && !this.state.oldMode ? (
                <div className="d-flex flex-column align-items-center p-4">
                  <p className="text-center mb-3 fs-5" style={{ maxWidth: "500px", color: '#adb5bd' }}>
                    {<LocalizedText textKey="ConnectLovenseSmartphone" language={this.state.language} />}
                  </p>
          
                    <div className="d-flex justify-content-center mb-3">
                          <ButtonGroup>
                          <Button
                              variant="outline-light"
                              size="lg"
                              disabled={this.state.initializing}
                              onClick={this.toggleOldMode.bind(this)}
                              style={{
                                color: '#00b4d8',
                                borderColor: '#00b4d8',
                                borderRadius: '30px',
                                padding: '10px 20px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.5px'
                              }}
                            >
                             <LocalizedText textKey="OldMode" language={this.state.language} />
                            </Button>
                          </ButtonGroup>
                        </div>
          
                  {this.state.qrCode ? (
                    <img
                      src={this.state.qrCode.qrcodeUrl}
                      className="img-fluid rounded shadow mb-3 mt-4"
                      alt="QR Code"
                      style={{ maxWidth: "200px" }}
                    />
                  ) : (
                    <div className="d-flex justify-content-center mt-5 mb-4">
                      <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {!this.state.oldMode ? (
                    <>
                      <p className="text-muted text-center mb-4" style={{ color: '#adb5bd' }}>
                        {this.getText("ConnectLovenseSmartphone")}
                      </p>
          
                      {(this.state.initializing || this.state.fetching) && (
                        <div className="d-flex align-items-center justify-content-center mb-4">
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <span className="ms-2 text-muted" style={{ color: '#adb5bd' }}>{this.getText("loading")}</span>
                        </div>
                      )}
          
                      <div className="d-flex justify-content-center mb-4">
                        
                        <ButtonGroup>
                        <Button
                              variant="outline-light"
                              size="lg"
                              disabled={this.state.initializing}
                              onClick={this.toggleOldMode.bind(this)}
                              style={{
                                color: '#00b4d8',
                                borderColor: '#00b4d8',
                                borderRadius: '30px',
                                padding: '10px 20px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.5px'
                              }}
                            >
                             {this.getText("Old Mode")}
                            </Button>
                          </ButtonGroup>
                      </div>
          
                      <div className="form-check form-switch d-flex align-items-center mb-4">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          disabled={!this.state.lovenseAppConnected}
                          checked={this.state.allToysMode}
                          id="formSwitchAllToys"
                          onChange={this.switchAllToys.bind(this)}
                        />
                        <label className="form-check-label" htmlFor="formSwitchAllToys" style={{ color: '#ffffff' }}>
                          {this.getText("ControlAllToys")}
                        </label>
                      </div>
          
                      {this.state.allToysMode && (
                        <div className="d-flex justify-content-center mb-4 p-4">
                          <Button
                            variant="outline-light"
                            size="lg"
                            disabled={!this.state.lovenseAppConnected || !this.state.toyOnline}
                            onClick={this.clickAllToysMode.bind(this)}
                            style={{
                              color: '#ffffff',
                              borderColor: '#00b4d8',
                              backgroundColor: '#00b4d8',
                              borderRadius: '30px',
                              padding: '10px 20px',
                            }}
                          >
                            {this.getText("SetAllToysMode")}
                          </Button>
                        </div>
                      )}
          
                      <div id="lovenseDeviceList">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <span className="fw-semibold text-light">Toys</span>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            disabled={this.state.initializing || this.state.fetching}
                            onClick={this.updateToys.bind(this)}
                          >
                            <FontAwesomeIcon icon={faArrowsRotate} />
                          </button>
                        </div>
          
                        <div className="card shadow-sm mb-3" style={{ backgroundColor: '#272738', color: '#ffffff' }}>
                          <table className="table table-borderless mb-0">
                            <thead className="table-dark text-white">
                              <tr style={{ backgroundColor: '#272738', color: '#ffffff' }}>
                                <th>{this.getText("Type")}</th>
                                <th>{this.getText("Name")}</th>
                                <th>{this.getText("Status")}</th>
                                <th>{this.getText("Battery")}</th>
                                <th>{this.getText("Actions")}</th>
                              </tr>
                            </thead>
                            <tbody className='text-white'>
                              {this.state.devices.map((obj, index) => (
                                <LovenseDeviceDisplay
                                  key={obj.id}
                                  language={this.state.language}
                                  selectable={!this.state.allToysMode && this.state.lovenseAppConnected && this.state.toyOnline}
                                  selectCallback={this.selectLovenseDevice.bind(this)}
                                  device={obj}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
          
                      {this.state.deviceInfo && (
                        <div className="card p-3 mb-4 shadow-sm" style={{ backgroundColor: '#272738', color: '#ffffff' }}>
                          <table className="table table-borderless mb-0 text-white">
                            <tbody>
                              <tr>
                                <td><strong>Platform:</strong> {this.state.deviceInfo.platform}</td>
                                <td><strong>AppType:</strong> {this.state.deviceInfo.appType}</td>
                              </tr>
                              <tr>
                                <td><strong>Version:</strong> {this.state.deviceInfo.appVersion}</td>
                                <td>
                                  <strong>App:</strong> {this.state.lovenseAppConnected ? (
                                    <span className="badge bg-success text-white">Online</span>
                                  ) : (
                                    <span className="badge bg-secondary text-white">Offline</span>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-column align-items-center p-2">
                        <p className="text-center mb-3 fs-5" style={{ maxWidth: "500px", color: '#adb5bd' }}>
                          <LocalizedText textKey="NeedLovenseConnect" language={this.state.language} />
                        </p>
          
                        <div className="d-flex justify-content-center mb-3">
                          <ButtonGroup>
                            <Button
                              variant="outline-light"
                              size="lg"
                              disabled={this.state.initializing}
                              onClick={this.toggleOldMode.bind(this)}
                              style={{
                                color: '#00b4d8',
                                borderColor: '#00b4d8',
                                borderRadius: '30px',
                                padding: '10px 20px',
                                textTransform: 'uppercase',
                                letterSpacing: '0.5px'
                              }}
                            >
                              <LocalizedText textKey="UseAppVersion" language={this.state.language} />
                            </Button>
                          </ButtonGroup>
                        </div>
          
                        <LovenseDeviceSelect
                          choice="LAN"
                          language={this.state.language}
                          producerID={this.state.producerID}
                          pseudo={this.state.pseudo}
                          onSelect={this.onSelectOldDevice.bind(this)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Modal.Body>
          
            <Modal.Footer className="border-0 d-flex justify-content-center" style={{ backgroundColor: '#1b1c2a' }}>
              <Button
                variant="outline-light"
                size="sm"
                onClick={this.closeCallback.bind(this)}
                style={{
                  color: '#adb5bd',
                  borderColor: '#adb5bd',
                  borderRadius: '25px',
                  padding: '8px 16px',
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          
        )
    }
}

export default LovenseModal;