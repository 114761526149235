import React from 'react';

export interface LoginData {
  producerID:string;
  pseudo:string;
  hasChatline:boolean;
  allowFreeSound:boolean;
  allowFreeChat:boolean;
  dildoControl:boolean;
  fanAPIAuthToken:string;
  doUpdateUnread: boolean;
}

export interface ChatOptionsData {
  price:number;
  autotext1:string;
  autotext2:string;
  autotext3:string;
  autotext4:string;
  autotext5:string;
  stagingMode:boolean;
  dildoAllowed:boolean;
  welcomeMessage:string;
  noUserEnterSound:boolean;
  noUserLeaveSound:boolean;
  noChatMessageSound:boolean;
  autoEnableSound:boolean;
  autoSoundAccept:boolean;
  autoDildoAccept:boolean;
  autoUserMicAccept:boolean;
  autoCam2CamAccept:boolean;
  soundPrice:number;
  cam2camPrice:number;
  userMicPrice:number;
  dildoPrice:number;
  lovenseSessionPrice:number;
  privateSessionPrice:number;
  multiPrivatePrice:number;
  noFeatureRequestSound:boolean;
  result:string;
  error:string;
}

export interface AppContextType {
  loginData: LoginData | null;
  chatOptions: ChatOptionsData | null;
  language: string;
  encoderUi: any; 
  streamKey: string;
  enableSoup1: boolean;
  enableSoup2: boolean;
  enableSoup3: boolean;
  apiurl: string;
  sendLoginResult: (result: any) => void;
  changeLanguage: (language: string) => void;
  appVersion: string;
  jwtAuthToken: string;
}

// Setze Standardwerte für deinen Kontext
const defaultContextValue: AppContextType = {
  loginData: null,
  chatOptions: null,
  language: "de",
  encoderUi: null,
  streamKey: '',
  enableSoup1: true,
  enableSoup2: true,
  enableSoup3: false,
  appVersion: "5.0.0",
  apiurl: "https://api.deine.fans",
  jwtAuthToken: "",
  changeLanguage: (result: any) => {
    // Implementiere die Funktion oder lass sie leer, wenn keine Aktion benötigt wird
    console.warn("sendLoginResult function is not implemented yet.");
  },
  sendLoginResult: (result: any) => {
    // Implementiere die Funktion oder lass sie leer, wenn keine Aktion benötigt wird
    console.warn("sendLoginResult function is not implemented yet.");
  }
};

const AppContext = React.createContext<AppContextType>(defaultContextValue);

export default AppContext;
