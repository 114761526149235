import React from 'react';
import { useApiText } from './UseGetResourceString';

interface LocalizedTextProps {
    textKey: string; // Der Schlüssel für den Text (z. B. "AutoAcceptSound")
    language: string; // Die Sprache, in der der Text geladen werden soll
    placeholders?: Record<string, string>; // Optional: Platzhalter, die im Text ersetzt werden sollen
}

const LocalizedText: React.FC<LocalizedTextProps> = ({ textKey, language, placeholders }) => {
    let text = useApiText(textKey, language); // Lade den Text basierend auf Schlüssel und Sprache

    // Ersetze Platzhalter im Text, falls vorhanden
    if (placeholders) {
        Object.entries(placeholders).forEach(([key, value]) => {
            text = text.replace(`{${key}}`, value);
        });
    }

    return <>{text}</>;
};

export default LocalizedText;
