import React, { Component, FormEvent } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import AppContext from "../AppContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Redirect } from 'react-router';
import { VideoList,VideoData } from './VideoList';
import { MessagingPostMediaData } from './Timeline';
import VideoUpload from './VideoUpload';
import VideoSingleEdit from './VideoSingleEdit';

interface VideoModalProps {
    open:boolean;
    mediaAddMode: string;
    closeCallback():void;
    itemClickCallback(clickedImage:MessagingPostMediaData):void;
    itemSelectMulti(images:MessagingPostMediaData[]):void;
    changeMediaAddMode(mode:string):void;
}

export interface VideoModal extends React.Component {
    state: VideoModalState;
    props: VideoModalProps;
    updateTimer: NodeJS.Timeout | undefined;
}

interface VideoModalState {
    isOpen: boolean;
    errorMessage: string;
    infoMessage: string;
    fetching: boolean;
    language: string;
    videos: VideoData[];
    conversationID: string;
    mediaAddMode: string;
    selectedVideoData: VideoData|null;
    showUpload: boolean;
    lastVideoFolderName: string;
    lastVideoFolderGuid: string;
}

export class VideoModal extends React.Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: VideoModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            isOpen: props.open,
            errorMessage: "",
            infoMessage: "",
            fetching: false,
            language: contextLanguage,
            conversationID: "",
            videos: [],
            mediaAddMode: "",
            selectedVideoData: null,
            showUpload: false, 
            lastVideoFolderName: "",
            lastVideoFolderGuid: ""
        }
    }

    componentDidMount() {
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.open != this.state.isOpen) {
            this.setState({isOpen: this.props.open});
        }
        if(this.props.mediaAddMode !== this.state.mediaAddMode) {
            this.setState({mediaAddMode: this.props.mediaAddMode});
        }
    }

    componentWillUnmount() {
        if (this.updateTimer) {
            clearInterval(this.updateTimer);
        }
        }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if (exp === "NochOhneSoftVorschau") {
                return "Noch ohne Soft-Vorschau";
            }
            if (exp === "NochOhneHardVorschau") {
                return "Noch ohne Hard-Vorschau";
            }
            if(exp === "Change") {
                return "Ändern";
            }
        } else {
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "Reviewed") {
                return "Reviewed";
            }
            if (exp === "In review") {
                return "In review";
            }
            if (exp === "Public") {
                return "Public";
            }
            if (exp === "Not public") {
                return "Not public";
            }
            if (exp === "NochOhneSoftVorschau") {
                return "No preview";
            }
            if (exp === "NochOhneHardVorschau") {
                return "No preview";
            }
            if(exp === "Change") {
                return "Change";
            }
        }

        return exp;
    }

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    itemMultiSelect(videos:VideoData[]) {
        if(videos) {
            let multiArray:MessagingPostMediaData[] = [];

            for(let i:number = 0; i < videos.length; i++) {
                if(videos[i] !== null) {
                    multiArray.push({
                        mediaID: videos[i].streamname,
                        type: "video",
                        reviewStatus: videos[i].reviewStatus
                    });
                }
            }

            this.props.itemSelectMulti(multiArray);
        }
    }

    itemClicked(clickedItem:VideoData) {
        console.log("Video  " + clickedItem.streamname + " clicked");
        if(clickedItem) {
            this.props.itemClickCallback({
                mediaID: clickedItem.streamname,
                type: "video",
                reviewStatus: clickedItem.reviewStatus
            });
        } else {
            this.setState({errorMessage: "Invalid video clicked"});
        }
    }

    getHeader() {
        if(this.state.mediaAddMode === "free") {
            return "Kostenloses Video hinzufügen";
        }
        if(this.state.mediaAddMode === "pay") {
            return "Kostenpflichtiges Video hinzufügen";
        }
        if(this.state.mediaAddMode === "message") {
            return "Video zur Nachricht hinzufügen";
        }
    }

    changeMediaAddMode() {
        if(this.state.mediaAddMode === "free") {
            this.props.changeMediaAddMode("pay");
            this.setState({mediaAddMode: "pay"});
        }
        if(this.state.mediaAddMode === "pay") {
            this.props.changeMediaAddMode("free");
            this.setState({mediaAddMode: "free"});
        }
    }

    singleEditClose(lastVideo: VideoData|null) {
        if(lastVideo != null) {
            this.setState({ lastVideoFolderName: lastVideo.folderName, lastVideoFolderGuid: lastVideo.folderGuid, selectedVideoData: null });
        } else {
            this.setState({ selectedVideoData: null });
        }
    }

    confirmSelection(videos:VideoData[]) {
        if(videos) {
            let multiArray:MessagingPostMediaData[] = [];

            for(let i:number = 0; i < videos.length; i++) {
                if(videos[i] !== null) {
                    multiArray.push({
                        mediaID: videos[i].streamname,
                        type: "image",
                        reviewStatus: videos[i].reviewStatus
                    });
                }
            }

            this.props.itemSelectMulti(multiArray);
        }
    }
    uploadComplete() {
        // do nothing
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader close={<button className="close" onClick={this.modalClosed.bind(this)}>×</button>}>
                    { this.getHeader() }
                    { this.state.mediaAddMode === "free" || this.state.mediaAddMode === "pay" ?
                    <Button size="sm" onClick={this.changeMediaAddMode.bind(this)}>{this.getText("Change")}</Button>
                    : null }
                </ModalHeader>
                <ModalBody>
                    { this.state.showUpload && this.context ?
                    <div id="VideoListUpload">
                        <VideoUpload producerID={this.context.loginData!.producerID} authToken={this.context.loginData!.fanAPIAuthToken} uploadType="fanvideo" completeCallback={this.uploadComplete.bind(this)} />
                    </div> : null }
                    { this.state.selectedVideoData != null ?
                        <VideoSingleEdit streamName={this.state.selectedVideoData.streamname} closeCallback={this.singleEditClose.bind(this)} />
                    : 
                    <div>
                        <h4 className='pageHeadline'>
                            {this.getText("Videos")}
                            
                            <Button className='d-inline-block' color="secondary" onClick={() => this.setState({successMessage: "",errorMessage: "",showUpload: !this.state.showUpload})}><FontAwesomeIcon icon={solid('upload')} /> {this.getText("Upload")}</Button>
                        </h4>
                        <Alert color="secondary"><small>{this.getText("VideosPageInfo")}</small></Alert>
                        <VideoList singleSelect={true} folderName={this.state.lastVideoFolderName} folderGuid={this.state.lastVideoFolderGuid} onVideoClick={this.itemClicked.bind(this)} modalMode={true} confirmSelection={this.itemMultiSelect.bind(this)} mediaAddMode={this.state.mediaAddMode} />
                    </div> }
                </ModalBody>
            </Modal>
        )
    }
}

export default VideoModal;