import React, { Component, FormEvent } from 'react';
import { Alert, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import AppContext from "../AppContext";
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import ImageUpload from './ImageUpload';
import { FileUpload } from './ImageUpload';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import id from 'date-fns/esm/locale/id/index.js';
import { file } from '@babel/types';

interface IDShotManagerModalProps {
    open:boolean;
    closeCallback():void;
    selectCallback(idshot:IDShotRecord):void;
    streamname:string;
}

export interface IDShotManagerModal extends React.Component {
    state: IDShotManagerModalState;
    props: IDShotManagerModalProps;
}

interface IDShotManagerModalState {
    isOpen: boolean;
    newVorname:string;
    newNachname:string;
    newGeburtsdatum:Date;
    newGUID:string;
    errorMessage: string;
    infoMessage: string;
    fetching: boolean;
    language: string;
    idshots: IDShotRecord[];
    conversationID: string;
    clickedFileID: number;
    clickedFileName: string;
    clickedFileSize: number;
    clickedFilePreviewURL: string;
    clickedFileExtension: string;
    clickedFileRecordGUID: string;
    formMode: string;
    uploadedImage: boolean;
    uploadedDocument: boolean;
    streamname: string;
}

export interface IDShotRecord {
    guid: string;
    producerID: string;
    imageFileExtension: string;
    vorname: string;
    nachname: string;
    insertTimestamp: Date;
    geburtsdatum: Date;
    checkStatus: boolean;
    isRegistration: boolean;
    files: IDShotRecordFile[];
}

export interface IDShotRecordFile {
    id:number;
    filename:string;
    extension:string;
    filesize:number;
    previewURL:string;
}

export class IDShotManagerModal extends React.Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: IDShotManagerModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            isOpen: props.open,
            newVorname: "",
            newNachname: "",
            newGeburtsdatum: new Date(1990,1,1),
            newGUID: "",
            errorMessage: "",
            infoMessage: "",
            fetching: false,
            language: contextLanguage,
            conversationID: "",
            idshots: [],
            clickedFileID: 0,
            clickedFileName: "",
            clickedFileSize: 0,
            clickedFilePreviewURL: "",
            clickedFileExtension: "",
            clickedFileRecordGUID: "",
            formMode: "list",
            uploadedImage: false,
            uploadedDocument: false,
            streamname: props.streamname
        }

        registerLocale('de', de);
        registerLocale('en', en);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({ fetching: true });

        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        var jsonurl = this.context!.apiurl + "/idshots/";

        if (this.context) {
            jsonurl += encodeURIComponent(this.context.loginData!.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        }

        //jsonurl += "&dimension=" + encodeURIComponent("320x240xinside");

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                   
                }
            }

            if (data.idshots) {
                this.setState({ idshots: data.idshots });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    createIDShotClicked() {
        this.setState({ formMode: "new" });
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if( exp === "SelectIDShot") {
                return "Altersverfikation/Einverständniserklärung";
            }
            if (exp === "SelecthisIDShot") {
                return "Diese Person auswählen";
            }
            if (exp === "View") {
                return "Anzeigen";
            }
            if (exp === "CreateNewIDShot") {
                return "Neue Person anlegen";
            }
            if(exp === "PleaseAddFilesToThisIDShot") {
                return "Bitte lade für diese Person jetzt Ausweisbilder und Einverständniserklärung hoch";
            }
            if (exp === "AddFiles") {
                return "Dateien hinzufügen";
            }
            if(exp === "NoFiles") {
                return "Keine Dateien hochgeladen";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "CreateNewIDShot") {
                return "Neue Person anlegen";
            }
            if(exp === "CreateNewIDShotInstructions") {
                return "Trage bitte zuerst die Informationen zu der Person ein, die im Video bzw. auf Bildern sichtbar ist. Danach kannst du das Ausweisbild hochladen und erhältst einen Link zur Einverständniserklärung, die unterschrieben werden muss.";
            }
            if(exp === "ExistingIDShots") {
                return "Bereits angelegte Personen/Drehpartner";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Der Gesetzgeber verlangt von uns Alter und Identität der Darsteller in Deinen Videos sicher zu stellen. Du kannst hier ab sofort eine Liste von Darstellern anlegen, die in Deinen Filmen mitwirken und einen ID-Shot für jeden Darsteller hochladen. Zusätzlich benötigen wir die von Deinem Drehpartner unterschriebene Einverständniserklärung.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "Bei jedem Video, dass Du neu hochlädst musst Du angeben, wer neben Dir noch in den Videos mitwirkt. Erst dann können wir ein Video freigeben.";
            }
            if(exp === "NoIDShots") {
                return "Keine Personen angelegt";
            }
            if(exp === "PleaseUploadIDShotOfNewPerson") {
                return "Bitte lade nun ein Ausweisbild von der neu angelegten Person hoch.";
            }
            if(exp === "PleaseUploadIDShotOfNewPerson2") {
                return "Auf dem Ausweise sollten Name und Geburtsdatum der Person zu sehen sein.";
            }
            if(exp === "No Date set") {
                return "Kein Datum gesetzt";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "CancelUpload") {
                return "Upload beenden";
            }
            if(exp === "Bilddateien") {
                return "Bitte hier Bilddateien (jpg, png) hochladen";
            }
            if(exp === "WeNeedAusweisbilder") {
                return "Wir benötigen Bilder von Dir und Deinem Ausweis, um Deine Identität zu überprüfen. Dein Geburtsdatum muss auf dem Ausweis zu sehen sein und wir müssen Dein Gesicht mit dem Bild auf dem Ausweis vergleichen können.";
            }
            if(exp === "WeNeedEinverstaendniserklaerung") {
                return "Wir benötigen zusätzlich eine unterschriebene Einverständniserklärung von, Du kannst dazu das folgende Dokument verwenden und es ausdrucken, unterschreiben, einscannen und hochladen.";
            }
            if(exp === "PDFDateien") {
                return "Bitte hier PDF-Dateien hochladen";
            }
            if(exp === "BilddateienUploaded") {
                return "Bild wurde hochgeladen, Du kannst weitere hinzufügen oder den Upload beenden";
            }
            if(exp === "PDFDateienUploaded") {
                return "Datei wurde hochgeladen, Du kannst weitere hinzufügen oder den Upload beenden";
            }
        } else {
            if( exp === "SelectIDShot") {
                return "ID Shot/Letter of Consent";
            }
            if(exp === "WeNeedAusweisbilder") {
                return "We need pictures of the person and the ID to verify the identity. Your birthday must be visible on your ID and we must be able to compare your face with the picture on your ID.";
            }
            if(exp === "WeNeedEinverstaendniserklaerung") {
                return "We also need a signed consent form from the person. You can use the following document and print it, sign it, scan it and upload it.";
            }
            if(exp === "CreateNewIDShot") {
                return "Create new Person";
            }
            if(exp === "PleaseAddFilesToThisIDShot") {
                return "Please upload ID shot and consent form for this person shot now";
            }
            if (exp === "AddFiles") {
                return "Add files";
            }
            if(exp === "NoFiles") {
                return "No files uploaded";
            }
            if (exp === "Vorname") {
                return "First name";
            }
            if (exp === "Nachname") {
                return "Last name";
            }
            if (exp === "Geburtsdatum") {
                return "Birthday";
            }
            if(exp === "CreateNewIDShot") {
                return "Create new Person";
            }
            if(exp === "CreateNewIDShotInstructions") {
                return "Please enter the information about the person that is visible in the video or on the pictures first. Then you can upload the ID shot and get a link to the consent form that must be signed.";
            }
            if(exp === "ExistingIDShots") {
                return "Existing Persons";
            }
            if(exp === "Altersverifikation für Drehpartner") {
                return "Age verification for people in videos";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Legislation requires us to ensure the age and identity of the actors in your videos. You can create a list of actors who act in your videos and upload an ID shot for each actor. In addition, we need the declaration of consent signed by your shooting partner.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "For each new video that you upload, you have to indicate who else is involved in the videos alongside you. Only then can we release a video.";
            }
            if(exp === "NoIDShots") {
                return "No persons in list";
            }
            if(exp === "PleaseUploadIDShotOfNewPerson") {
                return "Please upload an ID picture of the newly created person.";
            }
            if(exp === "PleaseUploadIDShotOfNewPerson2") {
                return "The identity card should show the person's name and date of birth.";
            }
            if(exp === "Ausweisbilder") {
                return "ID shots";
            }
            if(exp === "Bilddateien") {
                return "Please upload picture files here (jpg, png)";
            }
            if(exp === "Einverständniserklärung") {
                return "Letter of consent / consent form";
            }
            if(exp === "CancelUpload") {
                return "Finish Upload";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "PDFDateien") {
                return "Please upload PDF files here";
            }
            if(exp === "BilddateienUploaded") {
                return "Picture was uploaded, you can add more or end the upload";
            }
            if(exp === "PDFDateienUploaded") {
                return "File was uploaded, you can add more or end the upload";
            }
        }

        return exp;
    }

    cancelCreateMode() {
        this.setState({ formMode: "list" });
    }

    cancelUploadMode() {
        this.setState({ formMode: "list" });
        this.setState({ uploadedImage: false });
        this.setState({ uploadedDocument: false });
    }

    createIDShot = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        let jsonurl = this.context!.apiurl + "/idshots";

        if (this.context) {
            jsonurl += "/" + encodeURIComponent(this.context.loginData!.producerID) + "?authToken=" + encodeURIComponent(this.context.loginData!.fanAPIAuthToken);
        }

        jsonurl += "&vorname=" + encodeURIComponent(this.state.newVorname);
        jsonurl += "&nachname=" + encodeURIComponent(this.state.newNachname);
        jsonurl += "&birthday=" + encodeURIComponent(DateTime.fromISO(this.state.newGeburtsdatum.toISOString()).toFormat("MM-dd-yyyy"));

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: 'PUT'});
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error creating idshot1: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error creating idshot2: " + data.error });
                } else {
                    this.setState({ infoMessage: data.message });
                }
            }

            if (typeof(data.guid) != "undefined") {
                this.fetchData();
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error creating idshot3: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    idshotUploadSelect(idshot:IDShotRecord) {
        this.props.selectCallback(idshot);
    }

    idshotUploadClick(idshot:IDShotRecord) {
        this.setState({ newGUID: idshot.guid });
        this.setState({ newVorname: idshot.vorname });
        this.setState({ newNachname: idshot.nachname });
        this.setState({ newGeburtsdatum: new Date(idshot.geburtsdatum) });
        this.setState({ formMode: "upload" });
        this.setState({ uploadedImage: false });
        this.setState({ uploadedDocument: false });
    }

    idshotDeleteClick = async(guid:string) => {
        console.log("IDShotManagerModal idshotDeleteClick");

        var jsonurl = this.context!.apiurl + "/idshots/" + encodeURIComponent(guid);

        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.loginData!.fanAPIAuthToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: 'DELETE'});
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error deleting idshot1: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error deleting idshot2: " + data.error });
                } else {
                    this.setState({ infoMessage: data.message });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error deleting idshot3: " + error.toString() });
            this.setState({ fetching: false });
        }

        this.fetchData();
    }

    fileClicked(recordGuid:string,file:IDShotRecordFile) {
        console.log("IDShotManagerModal file click " + file.filename + " | recordGuid: " + recordGuid);
        this.setState({ clickedFileRecordGUID: recordGuid });
        this.setState({ clickedFileID: file.id });
        this.setState({ clickedFileName: file.filename });
        this.setState({ clickedFileSize: file.filesize });
        this.setState({ clickedFileExtension: file.extension });
        this.setState({ clickedFilePreviewURL: file.previewURL });
    }

    uploadFinish(msg:string) {
        this.setState({infoMessage: msg});
        this.setState({errorMessage: ""});
        this.fetchData();
        this.setState({ uploadedImage: true });
    }

    uploadFinishFile(msg:string) {
        this.setState({infoMessage: msg});
        this.setState({errorMessage: ""});
        this.fetchData();
        this.setState({ uploadedDocument: true });
    }

    clickedMediaDataModalClose() {
        this.setState({ clickedFileRecordGUID: "" });
        this.setState({ clickedFileID: 0 });
        this.setState({ clickedFileName: "" });
        this.setState({ clickedFileSize: 0 });
        this.setState({ clickedFileExtension: "" });
        this.setState({ clickedFileGuid: "" });
    }

    deleteClickedMediaData = async() => {
        console.log("IDShotManagerModal deleteClickedMediaData");

        if(this.state.clickedFileRecordGUID === "") {
            this.setState({errorMessage: "No record selected"});
            return;
        }

        if(this.state.clickedFileID === 0) {
            this.setState({errorMessage: "No file selected"});
            return;
        }

        var jsonurl = this.context!.apiurl + "/idshotfile";

        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        jsonurl += "?fileid=" + this.state.clickedFileID;

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.loginData!.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.loginData!.fanAPIAuthToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: 'DELETE'});
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error deleting file1: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error deleting file2: " + data.error });
                } else {
                    this.setState({ infoMessage: data.message });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error deleting file3: " + error.toString() });
            this.setState({ fetching: false });
        }

        this.clickedMediaDataModalClose();

        this.fetchData();
    }

    

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    render() {

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader close={<button className="close" onClick={this.modalClosed.bind(this)}>×</button>}>
                    {this.getText("SelectIDShot")}
                </ModalHeader>
                <ModalBody>
                    <div id='IDShotsListDIV'>
                        <Modal isOpen={this.state.clickedFileName !== ""} size="md" onClosed={this.clickedMediaDataModalClose.bind(this)}>
                            <ModalHeader close={<button className="close" onClick={this.clickedMediaDataModalClose.bind(this)}>×</button>}>
                                File { this.state.clickedFileID.toString() }
                            </ModalHeader>
                            <ModalBody>
                            { this.state.clickedFileExtension === ".pdf" ? <iframe src={this.state.clickedFilePreviewURL}></iframe> : <img src={this.state.clickedFilePreviewURL} alt={this.state.clickedFileName} /> }
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={this.deleteClickedMediaData.bind(this)}>{this.getText("Bild aus dem Record löschen")}</Button>
                                <Button onClick={this.clickedMediaDataModalClose.bind(this)}>Close</Button>
                            </ModalFooter>
                        </Modal>

                        <Alert className='mt-2' color="info">
                            <h5>{this.getText("Altersverifikation für Drehpartner")}</h5>
                            <p><small>{this.getText("Altersverifikation für Drehpartner Description")}</small></p>
                            <p>
                                <small>{this.getText("WeNeedEinverstaendniserklaerung")}</small><br></br>
                                <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller.pdf" target="_blank" rel="noopener noreferrer">Einverständniserklärung</a>&nbsp;&nbsp;
                                <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller+English.pdf" target="_blank" rel="noopener noreferrer">Letter of Consent</a>
                            </p>
                        </Alert>

                        <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                        <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>

                        { this.state.formMode === "list" ?
                        <p>
                            <Button onClick={this.createIDShotClicked.bind(this)}>{this.getText("CreateNewIDShot")}</Button>
                        </p>
                        : null }

                        { this.state.formMode === "new" ?
                        <Form onSubmit={this.createIDShot.bind(this)}>
                            <h5>{this.getText("CreateNewIDShot")}</h5>
                            <p>{this.getText("CreateNewIDShotInstructions")}</p>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="newVorname">{this.getText("Vorname")}</Label>
                                        <Input type="text" id="newVorname" value={this.state.newVorname} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({newVorname: e.target.value})}} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="newNachname">{this.getText("Nachname")}</Label>
                                        <Input type="text" id="newNachname" value={this.state.newNachname} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({newNachname: e.target.value})}} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <FormGroup>
                                        <Label for="birthday">{this.getText("Geburtsdatum")} ({ typeof(this.state.newGeburtsdatum.toISOString) === "function" ? DateTime.fromISO(this.state.newGeburtsdatum.toISOString()).toLocaleString(DateTime.DATE_MED) : this.getText("No Date set") })</Label>
                                        <DatePicker selected={ this.state.newGeburtsdatum } showMonthDropdown showYearDropdown dropdownMode="select" locale={this.state.language === 'de' ? de : en} onChange={(newDate:Date) => {this.setState({newGeburtsdatum: newDate})}} />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <div className='mt-2'>
                                        <Button type="submit" variant="primary">{this.getText("CreateNewIDShot")}</Button>&nbsp;
                                        <Button onClick={this.cancelCreateMode.bind(this)} type="button" variant="secondary">{this.getText("Cancel")}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        : null }

                        { this.state.formMode === "upload" ?
                        <div>
                            <h5>{this.getText("PleaseUploadIDShotOfNewPerson")}</h5>
                            <p>{this.getText("PleaseUploadIDShotOfNewPerson2")}</p>
                            <table className="w-100">
                                <tr>
                                    <td className="w-50">{this.getText("Vorname")}</td>
                                    <td className="w-50"><span>{this.state.newVorname}</span></td>
                                </tr>
                                <tr>
                                    <td className="w-50">{this.getText("Nachname")}</td>
                                    <td className="w-50"><span>{this.state.newNachname}</span></td>
                                </tr>
                                <tr>
                                    <td className="w-50">{this.getText("Geburtsdatum")}</td>
                                    <td className="w-50">{typeof(this.state.newGeburtsdatum.toISOString) === "function" ? DateTime.fromISO(this.state.newGeburtsdatum.toISOString()).toLocaleString(DateTime.DATE_MED) : this.getText("No Date set")}</td>
                                </tr>
                            </table>
                            <hr></hr>
                            <div>
                                <h5>{this.getText("Ausweisbild")}</h5>
                                <p>{this.getText("WeNeedAusweisbilder")}</p>
                                <p>{ this.state.uploadedImage ? <span>{this.getText("BilddateienUploaded")}</span> : <small>{this.getText("Bilddateien")}</small> }</p>
                                <ImageUpload producerID={this.context.loginData!.producerID} uploadInfo={"idshot-" + this.state.newGUID} finishCallback={this.uploadFinish.bind(this)} />
                            </div>
                            <div>
                                <h5>{this.getText("Einverständniserklärung")}</h5>
                                <p>
                                    {this.getText("WeNeedEinverstaendniserklaerung")}<br></br>
                                    <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller.pdf" target="_blank" rel="noopener noreferrer">Einverständniserklärung</a>&nbsp;&nbsp;
                                    <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller+English.pdf" target="_blank" rel="noopener noreferrer">Letter of Consent</a>
                                </p>
                                <p>{ this.state.uploadedDocument ? <span>{this.getText("PDFDateienUploaded")}</span> : <small>{this.getText("PDFDateien")}</small> }</p>
                                <FileUpload producerID={this.context.loginData!.producerID} uploadInfo={"idshot-" + this.state.newGUID} finishCallback={this.uploadFinishFile.bind(this)} />
                            </div>
                            <div>
                                <Button onClick={this.cancelUploadMode.bind(this)} type="button" variant="secondary" size="sm">{this.getText("CancelUpload")}</Button>
                            </div>
                        </div> : null }

                        <div id="IDShotsListPane" className='mt-4'>
                            <h5>{this.getText("ExistingIDShots")} <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.fetchData.bind(this)} icon={solid('refresh')} fixedWidth /></h5>
                            {this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                    {this.state.idshots.length === 0 ? <p>{this.getText("NoIDShots")}</p> :
                                    this.state.idshots.map(idshot =>
                                        <div className="idshotItem">
                                            <div className="d-flex justify-content-between w-100">
                                                <small>{DateTime.fromISO(idshot.insertTimestamp.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                                <div>
                                                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.idshotUploadClick.bind(this,idshot)} icon={solid('upload')} fixedWidth />
                                                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.idshotDeleteClick.bind(this,idshot.guid)} icon={solid('remove')} fixedWidth />
                                                </div>
                                            </div>
                                            <div className="w-100">
                                                <table className="idshotItemTable w-100">
                                                    <tr>
                                                        <td className="w-50">{this.getText("Vorname")}</td>
                                                        <td className="w-50"><span>{idshot.vorname}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-50">{this.getText("Nachname")}</td>
                                                        <td className="w-50"><span>{idshot.nachname}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-50">{this.getText("Geburtsdatum")}</td>
                                                        { idshot.geburtsdatum ? 
                                                        <td className="w-50">{ typeof(idshot.geburtsdatum.toISOString) === "function" ? DateTime.fromISO(idshot.geburtsdatum.toISOString()).toLocaleString(DateTime.DATE_MED) : DateTime.fromISO(idshot.geburtsdatum.toString()).toLocaleString(DateTime.DATE_MED)}</td>
                                                        : <td className="w-50">{this.getText("Unknown")}</td> }
                                                    </tr>
                                                    <tr>
                                                        <td className="w-50">{this.getText("Status")}</td>
                                                        <td className="w-50">
                                                            {idshot.files.length === 0 ? 
                                                            <span><FontAwesomeIcon icon={solid('exclamation-triangle')} fixedWidth /> {this.getText("NoFiles")}</span> : 
                                                            idshot.checkStatus ? <span><FontAwesomeIcon icon={solid('square-check')} fixedWidth /> {this.getText("Reviewed")}</span> : 
                                                            <span><FontAwesomeIcon icon={solid('magnifying-glass')} fixedWidth /> {this.getText("In review")}</span> }
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <hr></hr>
                                            <div className='w-100 d-flex'>
                                                {idshot.files.length === 0 ? 
                                                <p>
                                                    <p>{this.getText("PleaseAddFilesToThisIDShot")}</p>
                                                </p> 
                                                :
                                                idshot.files.map(file =>
                                                    <div className="idshotFileItem">
                                                        { file.extension === ".pdf" ? 
                                                        <div className='idshotPDFFile'>
                                                            <iframe className='w-100' src={file.previewURL}></iframe>
                                                            <Button className='d-block' onClick={this.fileClicked.bind(this,idshot.guid,file)} variant="secondary" size="sm">{this.getText("View")}</Button>
                                                        </div> : 
                                                        <div className='idshotImageFile'>
                                                            <img src={file.previewURL} onClick={this.idshotUploadSelect.bind(this,idshot)} style={{cursor: "pointer"}} alt={"File " + file.id.toString()} />
                                                            <Button className='d-block' onClick={this.fileClicked.bind(this,idshot.guid,file)} variant="secondary" size="sm">{this.getText("View")}</Button>
                                                        </div> }
                                                    </div>
                                                )}
                                            </div>
                                            <p>
                                                <Button variant="primary" onClick={this.idshotUploadSelect.bind(this,idshot)}><FontAwesomeIcon style={{cursor: "pointer"}} icon={solid('square-check')} fixedWidth /> {this.getText("SelecthisIDShot")}</Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button variant="primary" onClick={this.idshotUploadClick.bind(this,idshot)}><FontAwesomeIcon style={{cursor: "pointer"}} icon={solid('upload')} fixedWidth /> {this.getText("AddFiles")}</Button>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default IDShotManagerModal;