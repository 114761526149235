import { useEffect, useState } from 'react';

// Helper-Klasse für API-Aufrufe und LocalStorage
class ApiTextHelper {
    private static instance: ApiTextHelper;
    private localStorageWorker = new LocalStorageWorker();

    static getInstance(): ApiTextHelper {
        if (!ApiTextHelper.instance) {
            ApiTextHelper.instance = new ApiTextHelper();
        }
        return ApiTextHelper.instance;
    }

    async fetchText(key: string, language: string): Promise<string> {
        const cacheKey = `${key}_${language}`;
        const cachedText = this.localStorageWorker.get(cacheKey);
        if (cachedText) {
            return cachedText;
        }

        try {
            const newLang = language === 'auto' ? 'de' : language;
            const response = await fetch(
                `https://vhsue2beba.execute-api.eu-west-1.amazonaws.com/api/${key}?culture=${newLang}`
            );
            if (!response.ok) {
                throw new Error('Failed to fetch API text!');
            }

            let data = await response.text();
            if (data.startsWith('"') && data.endsWith('"')) {
                data = data.slice(1, -1);
            }

            this.localStorageWorker.add(cacheKey, data);
            return data;
        } catch (error) {
            console.error(`Error fetching API text for key ${key}:`, error);
            return key; // Fallback to key if fetch fails
        }
    }
}

// LocalStorage Worker
class LocalStorageWorker {
    get(key: string): string | null {
        return localStorage.getItem(key);
    }
    add(key: string, value: string): void {
        localStorage.setItem(key, value);
    }
}

// React Hook für API-Texte
export const useApiText = (key: string, language: string) => {
    const [text, setText] = useState<string>('Loading...');

    useEffect(() => {
        let isMounted = true;

        const fetchText = async () => {
            const helper = ApiTextHelper.getInstance();
            const fetchedText = await helper.fetchText(key, language);
            if (isMounted) {
                setText(fetchedText);
            }
        };

        fetchText();

        return () => {
            isMounted = false; // Cleanup
        };
    }, [key, language]);

    return text;
};
